/* App.css */
.App {
  text-align: center;
  justify-content: center;
}

.header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.word-row {
  display: flex;
  justify-content: center;
  padding: 2px;
}

.word-block {
  width: 40px;
  height: 40px;
  border: 1px solid #e6dada; /* Use a light border for contrast */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1px;
  font-size: 24px; /* Adjust size as needed */
  font-weight: bold;
}

@media (max-width: 425px) {
  .word-block {
    width: 40px;
    height: 40px;
    font-size: 16px; /* Adjust size as needed */
    font-weight: bold;
  }
}

@media (max-width: 425px) {
  .word-row {
    width: 100%;
    height: 100%;
    font-size: 16px; /* Adjust size as needed */
    font-weight: bold;
  }
}

.game-area {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  max-width: 425px;
  height: 100%;
  margin: auto;
  padding: 0;
}

@media (max-width: 425px) {
  .game-area {
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 425px) {
  .App {
    text-align: center;
    justify-content: center;
  }
}

.word-block.correct-position {
  background-color: green;
}

.word-block.wrong-position {
  background-color: rgb(190, 190, 63);
}

.word-block.incorrect {
  background-color: grey;
}

body {
  background-color: #282c34;
  color: #ffffff;
}

.row {
  display: flex;
  justify-content: space-between;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modal-content {
  position: relative;
  padding: 20px;
  width: 80%;
  max-width: 500px;
  background: black;
  border-radius: 10px;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 24px;
  cursor: pointer;
}
.center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* takes full viewport height */
}

.qumi-title {
  font-family: 'Arial', sans-serif; /* or any similar font */
  font-size: 2.5em;
  font-weight: bold;
  color: #ced8ce; 
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* subtle shadow for depth */
  margin-bottom: 20px;
}

.image-placeholder {
  width: 150px;
  height: 150px;
  background-color: #E0E0E0; /* Placeholder color, adjust as needed */
  margin-bottom: 20px;
  /* When you add your image, remove this and replace with img tag */
}

.start-buttons {
  display: flex;
  flex-direction: row;  /* make it side by side */
  align-items: center;
  gap: 20px;
}

.how-to-play-btn, .play-btn {
  padding: 15px 30px;
  font-size: 1.2em;
  border: none;
  border-radius: 25px; /* rounded corners */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.buy-me-coffee-btn {
  margin-top: 15px;
  padding: 15px 30px;
  font-size: 1.2em;
  border: none;
  border-radius: 25px; /* rounded corners */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.how-to-play-btn:hover, .play-btn:hover, .buy-me-coffee-btn:hover {
  transform: scale(1.05); /* slight grow effect on hover */
}

.start-btn:hover {
  transform: scale(1.05); /* slight grow effect on hover */
  background-color: #176F16; /* Darker shade of green */
}

.how-to-play-btn {
  background-color: #FFD700; /* gold color, change as desired */
  color: black;
}

.buy-me-coffee-btn {
  background-color: #FFD700; /* gold color, change as desired */
  color: black;
}

.play-btn {
  background-color: #449644; /* forestgreen, change as desired */
  color: white;
}

.start-btn {
  min-width: 150px; /* Ensures both buttons have a minimum width */
  padding: 10px 20px;
  background-color: #228B22; /* Feel free to adjust color */
  color: white;
  border: none;
  border-radius: 25px; /* Rounded corners */
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}



