.share-btn, .download-picture-btn {
    padding: 15px 30px;
    font-size: 1.2em;
    border: none;
    border-radius: 25px; /* rounded corners */
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
.share-btn:hover, .download-picture-btn:hover {
    transform: scale(1.05); /* slight grow effect on hover */
}

.share-btn {
    background-color: #FFD700; /* gold color, change as desired */
    color: black;
}
  
.download-picture-btn {
    background-color: #449644; /* forestgreen, change as desired */
    color: white;
}

.start-buttons-modal {
    display: flex;
    flex-direction: row;  /* make it side by side */
    align-items: center;
    gap: 20px;
    justify-content: center;
  }