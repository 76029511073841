  .keyboard {
    display: grid;
    align-items: center;
    grid-template-rows: repeat(5, 1fr);
    gap: 10px;
    padding-top: 10%;
    bottom: 0; 
    width: 100%; 
  }

  .keyboard-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 425px;
    height: 100%;
    margin: auto;
    padding: 0;
  }

  @media (min-width: 425px) {
    .keyboard {
      padding: 10px;
      margin: 1px;
      width: 100%; /* Ensures the keyboard stretches across the full width of the screen */
      padding-top: 10%;
      bottom: 10px; /* Aligns the keyboard to the bottom of the screen */
      width: 100%; /* Ensures the keyboard stretches across the full width of the screen */
    }
  }
  
  .keyboard-row {
    display: flex;
    justify-content: space-evenly;
  }
  
  .key {
    padding: 5px;
    margin: 2px;
    border: 1px solid rgb(78, 73, 73);
    border-radius: 5px; /* Rounded corners */
    background-color: rgb(177, 173, 173);
    color:rgb(255, 255, 255);
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    flex-grow: 1;
    text-align: center;
  }
  
  @media (min-width: 425px) {
    .key {
      padding: 10px;
      margin: 3px;
    }
  }

  .key.grey {
    background-color: rgb(51, 50, 50);
  }
  
  .key.yellow {
    background-color: rgb(190, 190, 63);
  }
  
  .key.green {
    background-color: green;
  }
  
  